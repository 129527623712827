import { createSlice } from "@reduxjs/toolkit";

export const partnerSlice = createSlice({
  name: "partnerDetails",
  initialState: {
    partnerDetails: null,
    pageStatus : null,
  },
  reducers: {
    setParnerDetails: (state, action) => {
      state.partnerDetails = action.payload;
    },
    setPageStatus: (state, action) => {
      state.pageStatus = action.payload;
    },
    clearPartnerDetails: (state) => {
      state.partnerDetails = {};
    },
  },
});
export const { setParnerDetails,setPageStatus, clearPartnerDetails } = partnerSlice.actions;
export default partnerSlice.reducer;
