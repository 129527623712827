import AuthForm from '../../components/AuthForm';
import AuthLayout from '../../components/AuthLayout';
import TextInput from '../../components/TextInput';
import userIcon from '../../assets/img/icons/user-icon.svg';
import emailIcon from '../../assets/img/icons/mail-icon.svg';
import companyIcon from '../../assets/img/icons/company-icon.svg';
import phoneIcon from '../../assets/img/icons/phone-icon.svg';
import './style.css'
import patternOne from '../../assets/img/shapes/pattern-1.svg'
import patternTwo from '../../assets/img/shapes/pattern-2.svg'
import Checkbox from '../../components/Checkbox';
import { useState } from 'react';
import CountrySelector from '../../components/CountrySelector';
import TimezoneSelector from '../../components/Timezone';
import CountryList from '../../components/common/CountryList';
import { useDispatch, useSelector } from "react-redux";
import { fetchData, postData } from "../../data/register/RegisterService";
import { useEffect } from "react";
import { setCredentials } from "../../data/redux-config/authSlice";
import { Link, useNavigate } from "react-router-dom";
import RegisterForm from "../../data/register/RegisterForm";
import PhoneInput from '../../components/PhoneInputField';
//import PhoneInput from "react-phone-input-2";
import {Select, Spin } from "antd";

const Register = () => {
    const dispatch = useDispatch();
    const { values, errors,timezones, spinning, handleChange, handleSubmit, handleCountry,handlePhone,handleClick,handleTimezoneChange } = RegisterForm();
    const navigate = useNavigate();
    return (
        <form
            onSubmit={handleSubmit}
            noValidate
            onKeyDown={(e) =>
                e.code === "Enter" || e.code === "NumpadEnter" ? handleSubmit(e) : ""
            }
       >
        <AuthLayout
            className="mew-register"
            leftContent={
                <div className='mew-left-content p-5 mew-register-bg'>
                    
                    <div className='mew-register-text-container'>
                        <h1>What We Provide !</h1>
                        <p>A centralized platform to manage marketplaces, digital marketing, direct-to-consumer, global expansion, first-party retail, drop ship and fulfillment needs.</p>
                        <p className='mew-bullet'>We help stores, brands and distributors find millions of new customers by connecting them to the biggest marketplaces</p>
                        <p className='mew-bullet'>Increases occupancy, saves time, boosts direct bookings and maximizes online sales.</p>
                        <p className='mew-bullet'>Connecting with customers, supporting third-party partners and managing vendors.</p>
                    </div>
                </div>
            }
            rightContent={
                <>
                <Spin spinning={spinning}>
                <AuthForm
                    columns={2}
                    heading="Create an Account"
                    subheading="Enter your details in the given form"
                >
                    <TextInput
                        type='text'
                        placeholder="First Name"
                        leftIcon={userIcon}
                        altText="First Name"
                        id="first_name"
                        label="First Name"
                        required={true}
                        name="firstName"
                        onChange={handleChange}
                        value={values.firstName || ""}
                        Validation="true"
                        ValidationMessage={errors.firstName || ""}
                    />
                    <TextInput
                        type='text'
                        placeholder="Last Name"
                        leftIcon={userIcon}
                        altText="Last Name"
                        id="last_name"
                        label="Last Name"
                        required={true}
                        name="lastName"
                        onChange={handleChange}
                        value={values.lastName || ""}
                        Validation="true"
                        ValidationMessage={errors.lastName || ""}
                    />
                    <TextInput
                        type='email'
                        placeholder="Email"
                        leftIcon={emailIcon}
                        altText="Email"
                        id="email"
                        label="Email"
                        required={true}
                        name="email"
                        onChange={handleChange}
                        value={values.email || ""}
                        Validation="true"
                        ValidationMessage={errors.email || ""}
                    />
                    <CountrySelector
                        label="Country"
                        id="country_selector"
                        required={true}
                        name="country"
                        onChange={handleCountry}
                        value={values.country || ""}
                        country_code={""}
                        Validation="true"
                        ValidationMessage={errors.country || ""}
                        style={{border:"1px solid #B3D2D9", width: "50%", height: 30 }}
                        disabled={false}
                    />
                    <PhoneInput
                        defaultValue={values.phone || ""}
                        country={values.country_code || ""}
                        disableDropdown={true}
                        countryCodeEditable={false}
                        value={values.phoneNumber || ""}
                        enableSearch={true}
                        label="Phone Number"
                        placeholder="Phone"
                        name="phoneNumber"
                        onChange={handlePhone}
                        className="mew-text-input mew-left-icon"
                        required={true}
                        Validation="true"
                        ValidationMessage={errors.phone || ""}
                    />
                    <TimezoneSelector
                        name="timezone"
                        label = "Timezone"
                        placeholder = "Select Timezone"
                        required={true}
                        Validation="true"
                        ValidationMessage={errors.timezone || ""}
                        options= {timezones.map((tz) => ({ value: tz, label: tz }))}
                        className="mew-select"
                        dropdownMatchSelectWidth={true}
                        placement= "bottomLeft"
                        value={values.timezone || ""}
                        onChange={handleTimezoneChange}
                    />
                    <TextInput
                        type='text'
                        placeholder="Company"
                        leftIcon={companyIcon}
                        altText="company"
                        id="company"
                        label="Company"
                        required={true}
                        name="company"
                        onChange={handleChange}
                        value={values.company || ""}
                        Validation="true"
                        ValidationMessage={errors.company_name || ""}
                    />
                    <div className='d-flex flex-row justify-content-between w-100 flex-wrap'>
                        <Checkbox
                            label="By checking the box, you confirm That you agree to the storing and processing of our personel data by channelAdvisor as describe in the Privacy policy"
                            id="remember_me"
                            name="remember_me"
                            Validation="true"
                            ValidationMessage={errors.remember || ""}
                            onChange={handleChange}
                        />
                    </div>
                    <button className='mew-register-btn btn btn-primary'>Register</button>
                </AuthForm>
                </Spin>
                </>
            }
        />
        </form>
    )
}
export default Register