import axios from "axios";
const apiKey = "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9";
const apiCallErrorMessage = "Oops! Something went wrong, please try again";

export function ApiEndPoint() {
  //  const url = "http://localhost:8000/api/v1/";
   const url = "https://integration.myecomwizard.com/api/v1/" //  new live url for registration site
  // const url = "https://partner.digitalmesh.co.in/api/v1/";
  // const url = "https://demoapi.myecomwizard.com/api/v1/";
  return url;
}
export function getApiKey() {
  return apiKey;
}
export function ApiCallErrorMessage() {
  return apiCallErrorMessage;
}
export function formatNumber(value) {
  return value.toString(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function ApiHeaders(
  token,
  accept = "application/json",
  contentType = "application/json"
) {
  const headers = {
    Accept: accept,
    "Content-Type": contentType,
    "Api-key": apiKey,
    Authorization: `Bearer ${token}`,
  };
  return headers;
}

export function shopifyAppPath() {
  const appUrl = "https://testshopify.myecomwizard.com";
  return appUrl;
}

const APICall = async (method, url, body, params, authCode, bodyParams,fileUpload) => {
  url = params ? url + params : url;
  let baseUrl = ApiEndPoint() + url;
  let header = authHeader(authCode,fileUpload);
  try {
    const result = await axios(baseUrl, {
      method: method,
      data: body,
      headers: header,
      params: bodyParams ? bodyParams : "",
    });
    if (result) {
      return result.data;
    } else {
      return ""; // or any other appropriate value
    }
  } catch (error) {
    // Handle error here if needed
    throw error; // Rethrow the error to be caught at the calling site
  }
};
const authHeader = (authCode,fileUpload) => {
  if(fileUpload){
    return {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Api-key": "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9",
      Authorization: `Bearer ${authCode}`,
    };
  }
  if (authCode) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Api-key": "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9",
      Authorization: `Bearer ${authCode}`,
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Api-key": "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9",
    };
  }
};

export function GetAxiosCall(method, url, params, bodyParams) {
  let authCode = localStorage.getItem("Access-Token")
    ? localStorage.getItem("Access-Token")
    : "";
  const response = APICall(method, url, "", params, authCode, bodyParams,"");
  return response;
}
export function PostAxiosCall(method, url, body, params,fileUpload) {
  let authCode = localStorage.getItem("Access-Token")
    ? localStorage.getItem("Access-Token")
    : "";
  const response = APICall(method, url, body, params, authCode,"",fileUpload);
  return response;
}

export function getFilteredObjectValues(inputObject) {
  const outputObject = Object.fromEntries(
    Object.entries(inputObject || {}).filter(
      ([key, value]) => value !== null && value.length !== 0
    )
  );
  return outputObject;
}

export function validateCustomDateRange(filters) {
  return filters.filterByStartDate !== "" && filters.filterByEndDate !== "";
}

export function convertObjectToQueryParams(object) {
  return new URLSearchParams(object).toString();
}
