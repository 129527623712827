import { Result } from "antd";
import AuthLayout from "../../components/AuthLayout";
import { useEffect, useState } from "react";
import { fetchData, postData } from "../../data/common/CommonService";
import { useParams } from 'react-router-dom';

function PartnerEmailVerified() {

  const { id, email } = useParams();
  const [verifyStatus, setVerifyStatus] = useState("");

  let requestBody = {};

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const email = urlParams.get('email');
    requestBody = {
      id: id,
      email: email
    };
    emailVerification(requestBody);
  }, []);

  const emailVerification = (requestBody) => {
    postData('partner/emailVerification', requestBody, "POST").then((response) => {
      if (response.status == 200) {
        if (response.data.status == "verified") {
          setVerifyStatus("verified");
        } else if (response.data.status == "already verified") {
          setVerifyStatus("already_verified");
        }
      } else {
        setVerifyStatus("error");
      }
    });
  };


  return (
    <>
      {verifyStatus == "verified" ?
        <AuthLayout
          className="mew-login"
          leftContent={<div className="mew-left-content mew-login-bg"></div>}
          rightContent={
            <Result
              status="success"
              title="Congratulations! Your email has been successfully verified"
              subTitle="To finalize your onboarding process, one of our representatives will reach out to you soon to explore your needs and share details about our subscription plans. Thank you"
              style={{ height: 250, float: "initial", paddingBottom: 60 }}
            />
          } />
        : (verifyStatus == "already_verified") ?
          <AuthLayout
            className="mew-login"
            leftContent={<div className="mew-left-content mew-login-bg"></div>}
            rightContent={
              <Result
                status="success"
                title="Your email has been already verified"
                style={{ height: 250, float: "initial", paddingBottom: 60 }}
              />
            } />
          : <AuthLayout
            className="mew-login"
            leftContent={<div className="mew-left-content mew-login-bg"></div>}
            rightContent={
              <Result
                status="error"
                title="There are some problems with your operation."
                style={{ height: 250, float: "initial", paddingBottom: 60 }}
              />
            } />}
    </>
  );
}

export default PartnerEmailVerified;
