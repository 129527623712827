import { Result } from "antd";
import AuthLayout from "../../components/AuthLayout";

function WelcomePartner() {

  return (
      <AuthLayout
      className="mew-login"
      leftContent={<div className="mew-left-content mew-login-bg"></div>}
      rightContent={
      <Result
        status="success"
        title="Welcome to My E-Com Wizard!"
        subTitle="A confirmation has been dispatched to your designated email.
        Kindly review your inbox and confirm your email to finalize your registration process. 
        Upon completion, one of our executives will reach out to you. Please be aware that this invitation will lapse in 15 days."
        style={{ height: 250, float: "initial", paddingBottom: 60 }}
      />
      } />
  );
}

export default WelcomePartner;
