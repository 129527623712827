import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "./style.css";

const PhoneInputField = ({
  label,
  required,
  id,
  leftIcon,
  defaultValue,
  disabled,
  placeholder,
  onChange,
  country,
  Validation,
  ValidationMessage,
}) => {
  const [phone, setPhone] = useState(defaultValue);

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhone(value);
    if (onChange) {
      onChange(value, country, e, formattedValue);
    }
  };

  return (
    <div className="mew-input-container">
      {label && (
        <label htmlFor={id}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <div className="mew-phone-input">
        {leftIcon != null ? (
          <span className="mew-select-left-icon">
            <img src={leftIcon} alt="Phone" />
          </span>
        ) : null}
        <PhoneInput
          country={country}
          defaultValue={defaultValue}
          disableDropdown={true}
          //countryCodeEditable={false}
          value={phone}
          enableSearch={true}
          placeholder={placeholder}
          inputProps={{ name: "phoneNumber" }}
          specialLabel={""}
          name="phoneNumber"
          onChange={handlePhoneChange}
          className={
            leftIcon == null ? "mew-text-input" : "mew-text-input mew-left-icon"
          }
          disabled={disabled}
        />
      </div>
      {Validation ? (
        <div className="mew-validationMessage">
          <span>{ValidationMessage}</span>
        </div>
      ) : null}
    </div>
  );
};

export default PhoneInputField;
