import { GetAxiosCall, PostAxiosCall } from "../../utils/commonutils";
import { useState } from "react";
import { message } from "antd";

// Set the initial timeout duration (5 minutes in milliseconds)
const idleTimeout = 120 * 60 * 1000; // 5 minutes

// Variable to store the timeout ID
let timeoutId;

export const fetchData = async (url, params, bodyParams) => {
  try {
    checkIdleState();
    const response = await GetAxiosCall("GET", url, params, bodyParams);
    if (response.status === 307) {
      window.location.href = "/deactivated";
    } else if (response.status === 401) {
      window.location.href = "/";
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const postData = async (url, data, method, fileUpload = false) => {
  try {
    checkIdleState();
    const response = PostAxiosCall(method, url, data, "", fileUpload);
    return response;
  } catch (error) {
    throw error;
  }
};

function checkIdleState() {
  resetTimeout();
  document.addEventListener("mousemove", resetTimeout);
  document.addEventListener("keydown", resetTimeout);

  // Initial call to start the timeout
  resetTimeout();
}

function resetTimeout() {
  // Clear the existing timeout
  clearTimeout(timeoutId);

  // Set a new timeout
  timeoutId = setTimeout(() => {
    // Remove the accessToken from local storage
    //localStorage.removeItem('accessToken');
    localStorage.removeItem("Access-Token");

    // Perform any additional actions if needed

    message.destroy();
    message.warning(
      "Your session has expired due to inactivity. Please log in again."
    );
    setTimeout(() => {
      window.location.href = "/";
    }, 5000);

    // Reset the timeout again
    resetTimeout();
  }, idleTimeout);
}
