import axios from "axios";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const errorStatusCodes = [500, 404, 403, 429];
const excludedRoutes = { "/register": true, "/": true };

export function ResponseInterceptor() {
  axios.interceptors.response.use(
    function (response) {
      if (response) {
        const responseCode = parseInt(response.data.status);
        const currentPage = window.location.pathname;
        /** Show Error messages for status codes: [500, 404, 403, 429] */
        if (errorStatusCodes.includes(responseCode)) {
          showMessageAndRedirect(response.data.message,responseCode);
        } else if (responseCode === 401 && !excludedRoutes[currentPage]) {
          // Do not show 'Session expired' message on register and login pages
          showMessageAndRedirect(response.data.message,401);
        } else {
          return response;
        }
      }
    },
    function (error) {
      if (error.response) {
        return error.response;
      }
    }
  );

  const showMessageAndRedirect = (messageText,statusCode) => {
    message.destroy();
    if(messageText){
      message.error(messageText);
    }
    //message.destroy();
    const currentPage = window.location.pathname;
   // let authCode = localStorage.getItem("Access-Token") ? localStorage.getItem("Access-Token") : "";
    if (statusCode == 401 &&  !excludedRoutes[currentPage]) {
      window.location.href = "/";
    }
  };
}
