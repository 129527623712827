import './style.css';

const AuthForm = ({ heading, subheading, columns , children}) => {
    return (
        <div className="mew-auth-form">
            <div className='mew-auth-form-header'>
                <h2>{heading}</h2>
                <h6>{subheading}</h6>
            </div>
            <div className={`mew-auth-input-container mew-inputCol-${columns}`}>
                {children}
            </div>
        </div>
    );
};

export default AuthForm;
