import './style.css';

const Checkbox = ({ type, checked, onChange, id, label,Validation,ValidationMessage }) => {
  return (
    <>
    <div className="mew-input-checkbox">
        <input
          type={type === 'radio' ? 'radio' : 'checkbox'}
          checked={checked}
          onChange={onChange}
          id={id}
        />
        <label htmlFor={id}>
            {label}
        </label>
        
    </div>
    {Validation ? (
      <div className='mew-validationMessage'>
        <span className='text-danger'>{ValidationMessage}</span>
      </div>):null}
    </>
  );
};

export default Checkbox;
