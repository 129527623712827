import axios from "axios";
import {
  ApiEndPoint,
  GetAxiosCall,
  PostAxiosCall,
} from "../../utils/commonutils";

export const fetchData = async () => {
  try {
    const response = GetAxiosCall("GET", "get-user", "", "");
    return response;
  } catch (error) {
    throw error;
  }
};

export const postData = async (data) => {
  try {
    const response = PostAxiosCall("POST", "partner/register", data, "");
    return response;
  } catch (error) {
    throw error;
  }
};
