export const validate = (formValues,countryCodeSelected) => {
    let errors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexName = /^[a-zA-Z_\s-]+$/;
    const regexcompany_name = /^[a-zA-Z0-9-_,. ]+$/;
    const regexphone = /^[0-9+]+$/;
    countryCodeSelected = '+'+countryCodeSelected;
    var phone = formValues.phoneNumber;
    var phoneNumber = '';
    if (phone){
        //Split phone-number by space (Ex: +91 99651-48523)
        var spliltPhone = phone.split(' ');
        //Remove country code from the input to validate phone number
        spliltPhone.shift();
        //Join reamining numbers
        var phoneNumber = spliltPhone.join('');
        //Remove special charcters +-<space>
        const specialChars = {'+':'','(':'',')':'','-':''};
        phoneNumber = phoneNumber.replace(/[ )(+-]/g, s => specialChars[s]);
    }
    if (!formValues.firstName) {
      errors.firstName = "Firstname is required!";
    } else if (!regexName.test(formValues.firstName)) {
      errors.firstName = "Invalid Firstname";
    }
    if (!formValues.lastName) {
    errors.lastName = "Lastname is required!";
    } else if (!regexName.test(formValues.lastName)) {
    errors.lastName = "Invalid Lastname";
    }
    if (!formValues.email) {
    errors.email = "Email is required!";
    } else if (!regexEmail.test(formValues.email)) {
    errors.email = "Invalid Email";
    }
    if (!formValues.phoneNumber || formValues.phoneNumber == countryCodeSelected || ( phoneNumber && phoneNumber.length == 0) ){
        errors.phone= "Phone required!";
    }
    else if (phone.length != 0 && phone.length < 9){ 
        if(!regexphone.test(formValues.phoneNumber)){
            errors.phone = "Phone number is invalid!";  
        }
        else if(phone.length > 0 && phone.length < 7){
            errors.phone = "Phone number is too short!";    
        }else if(formValues.country_id!='101' && phone.length < 9){
            errors.phone = "Phone number is too short!";
        }        
    }
    if (!formValues.company) {
    errors.company_name = "Company is required!";
    } else if (!regexcompany_name.test(formValues.company)) {
    errors.company_name = "Invalid Company";
    }
    if(!formValues.country) {
    errors.country = "Country is required!";
    }
    if(!formValues.remember_me) {
        errors.remember = "Please accept partner policy!";
    }
    if (!formValues.timezone) {
        errors.timezone = "Timezone is required!";
    }
    return errors;
  };
  