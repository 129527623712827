import React, { useState } from 'react';
import './style.css';
import visibleEyeIcon from '../../assets/img/icons/visible-eye.svg';
import hiddenEyeIcon from '../../assets/img/icons/hidden-eye.svg';

const TextInput = ({
  type = 'text',
  id,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  disabled,
  readOnly,
  maxLength,
  minLength,
  required,
  pattern,
  autoComplete,
  autoFocus,
  className,
  altText,
  label,
  leftIcon,
  key,
  onPressEnter,
  Validation,
  ValidationMessage,
  defaultValue
}) => {
 
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && onPressEnter) {
      onPressEnter(e);
    }
    if (type== 'number'){
      e = e || window.event;
      var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
      var charStr = String.fromCharCode(charCode);
      /** Allow floating point values */
      if (!charStr.match(/^[0-9.]+$/))
        e.preventDefault();
      }
  };
  return (
    <div className="mew-input-container" key={key}>
      {label && (
        <label htmlFor={id}>
          {label}
          {required && <span>*</span>}
        </label>
      )}

      <div className={leftIcon ? 'mew-text-input mew-left-icon' : 'mew-text-input'}>
        {leftIcon && <span><img src={leftIcon} alt={altText} /></span>}
        <input
          type={isPasswordVisible ? 'text' : type}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          minLength={minLength}
          required={required}
          pattern={pattern}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={className}
          onKeyPress={handleKeyPress}
          defaultValue={(defaultValue || defaultValue == 0 )  ? defaultValue : "" }
        />
        {type === 'password' && (
          <button type="button" onClick={togglePasswordVisibility}>
            <img src={isPasswordVisible ? visibleEyeIcon : hiddenEyeIcon} alt={isPasswordVisible ? "Visible icon" : "Hidden Icon"} />
          </button>
        )}
      </div>
      {Validation ? (
      <div className='mew-validationMessage'>
        <span>{ValidationMessage}</span>
      </div>):null}
    </div>
  );
};

export default TextInput;
