import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import "antd/dist/antd.css";
import routes from "./routes/routes";

function App() {
  let useCustomRoutes = useRoutes(routes);
  return (
    <>{useCustomRoutes}</>
  );
}

export default App;
