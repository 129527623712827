import {
  configureStore,
  applyMiddleware
} from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import partnerReducer from "./partnerSlice";
import authReducer from "../redux-config/authSlice";
import { authApi } from "../redux-config/services/authService";
import thunk from "redux-thunk";
export default configureStore(
  {
    reducer: {
      user: userReducer,
      partner: partnerReducer,
      auth: authReducer,
      [authApi.reducerPath]: authApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(authApi.middleware),
  }
);
