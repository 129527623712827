
import PartnerEmailVerified from "../pages/Register/PartnerEmailVerified";
import Register from "../pages/Register/Register";
import Welcome from "../pages/Register/WelcomePartner";

let routes = [
  {
    path: "/",
    element: <Register />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/welcome-partner",
    element: <Welcome />,
  },
  {
    path: "/partner-email-verfied",
    element: <PartnerEmailVerified />,
  }
];
export default routes;
