import './style.css';
import Logo from '../../assets/img/logo.svg'

const AuthLayout = ({ leftContent, rightContent, className }) => {
  return (
    <div className={`mew-auth-layout ${className}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-5 d-none d-md-block mew-auth-left-content">{leftContent}</div>
          <div className="col-12 col-md-7 mew-auth-right-content">
            <div className="mew-auth-header">
              <img src={Logo} alt="" />
              {/* <a href="#">Have any Queries?</a> */}
            </div>
            <div className="mew-auth-form-container">
              {rightContent}
            </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
