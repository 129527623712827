import { createSlice } from "@reduxjs/toolkit";


export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.user = action.payload
    },
    clearUserDetails: (state) => {
      state.user = {}
    },
  },
});
export const { setUserDetails,clearUserDetails } = userSlice.actions;
export default userSlice.reducer;
