import { useState, useEffect } from "react";
import { validate } from "./RegisterFormValidationRules";
import { useNavigate } from "react-router-dom";
import { fetchData, postData } from "../../data/register/RegisterService";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../data/redux-config/authSlice";
import store from "../redux-config/store";
import moment from "moment";
import * as countriesAndTimezones from 'countries-and-timezones';

const RegisterForm = (form) => {
  const [values, setValues] = useState({});
  const [defaultValues, setDefaultValues] = useState(values);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [countryData, setCountryData] = useState({});
  const [timezones, setTimezones] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      let requestBody = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone: values.phoneNumber,
        company_name: values.company,
        country_id: values.country,
        timezone: values.timezone
      };
      setSpinning(true);
      postData(requestBody).then((registerInfo) => {
        if (registerInfo.status == 200) {
          navigate("/welcome-partner");
        } else if (registerInfo.status == 422) {
          setSpinning(false);
          /* Show server side validation errors */
          let validationErrors = registerInfo.data;
          var errorMsg = "Please fix the error(s)";
          /* Iterate errors and append to the error message */
          Object.keys(validationErrors).map((key, value) => {
            validationErrors[key].map((value, ind) =>
              setErrors((data) => ({
                ...data,
                [key]: value,
              }))
            );
          });
          setErrors(validationErrors);
          message.error(errorMsg);
        }
      });
    }
  }, [errors]);

  useEffect(() => {
    if (isSubmit === true) {
    }
  }, [isSubmit]);

  //set timezone in values whenever its changes
  const handleTimezoneChange = (event) => {
    if(event){
      const newValues = { ...values, ["timezone"]: event };
      setValues(newValues);
    }else{
      const newValues = { ...values, ["timezone"]: "" };
      setValues(newValues);
    }
  };

  const handleCountry = (countryCode) => {
    if (countryCode) {
      values.country_code = countryCode.toLowerCase();
      const countryObj = {
        target: { id: "phoneNumber", name: "country", value: countryCode },
      };
      handleChange(countryObj);
      const countryInfo = countriesAndTimezones.getCountry(countryCode);
      const countryTimezones = countryInfo ? countryInfo.timezones : [];
      setTimezones(countryTimezones);
    } else {
      const newValues = { ...values, ["country"]: "" };
      setValues(newValues);
    }
  };

  const handlePhone = (value, country, e) => {
    handleChange(e);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setDisabled(true);
    setIsSubmit(false);
    setErrors("");
    if (event && event.type == "click") {
      setValues(defaultValues);
      navigate("/partner/profile");
      navigate("/partner/profile/personal-details");
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    let name = event.target.name;
    if (name == "login") {
      navigate("/");
    }
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
      setErrors(validate(values));
      setIsSubmit(true);
  };

  const handleChange = (event) => {
    setIsSubmit(false);
    if (event.target.id == "remember_me") {
      let checked = event.target.checked;
      values.remember_me = checked;
    }
    const newValues = { ...values, [event.target.name]: event.target.value };
    setValues(newValues);
  };

  return {
    handleClick,
    handleChange,
    handleSubmit,
    handleCancel,
    handlePhone,
    handleCountry,
    handleTimezoneChange,
    values,
    defaultValues,
    errors,
    disabled,
    countryData,
    timezones,
    spinning
  };
};
export default RegisterForm;
